<template>
    <div>
        <h1>Regions</h1>
        <table class='table'>
            <thead>
                <tr>
                    <th>Region</th>
                    <th>Truckload Full</th>
                    <th>Truckload Half</th>
                    <th>Flatbed Full</th>
                    <th>Flatbed Half</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for='(region, index) in state.object.data.rows' v-bind:key='index'>
                    <td>{{ region.name }}</td>
                    <td><input class='form-control' type='text' v-model='region.noflat_nohalf' @keypress.enter='save(region.noflat_nohalf_cr_id, region.noflat_nohalf)' /></td>
                    <td><input class='form-control' type='text' v-model='region.noflat_half' @keypress.enter='save(region.noflat_half_cr_id, region.noflat_half)' /></td>
                    <td><input class='form-control' type='text' v-model='region.flat_nohalf' @keypress.enter='save(region.flat_nohalf_cr_id, region.flat_nohalf)' /></td>
                    <td><input class='form-control' type='text' v-model='region.flat_half' @keypress.enter='save(region.flat_half_cr_id, region.flat_half)' /></td>
                </tr>
            </tbody>
        </table>
        <History :history='state.object.data.history' />
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import History from "@/components/businessObjects/utils/History";
import { regionStore } from './Region.store';

export default {
    name: 'Container',
    components: {History},
    data() {
        return {
            state: store.state,
            regionState: regionStore.state,
            regions: []
        }
    },
    methods: {
        save(regionId, value) {
            this.state.object.id = regionId;
            store.apiWithObjectLoad('save_region', {'params[freight_price]' : value});
        }
    },
    created() {
        store.initialize(this.regionState);
        store.apiWithObjectLoad('to_json', {'params[search_index]' : 0});
    }
}

</script>